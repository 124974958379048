<template>
  <div class="sidebar-details">
    <div class="sidebar-details__title">
      {{ this.$t('sparkpay.sales.detailed-sales-sidebar.details') }}
    </div>

    <div class="sidebar-details__item">
      <div class="sidebar-details__item-icon">
        <i class="fal fa-box-usd" />
      </div>

      <div class="sidebar-details__item-content">
        <div class="sidebar-details__product-title">
          {{ sale.product }}
        </div>

        <div class="sidebar-details__product-description">
          <span class="sidebar-details__subtitle-light">
            {{ this.$t('sparkpay.sales.detailed-sales-sidebar.sale-id') }}
          </span>

          <span class="sidebar-details__description-value">
            {{ sale.id }}
          </span>
        </div>
      </div>
    </div>

    <div v-if="content.canceledStatus" data-testid="canceled-status" class="sidebar-details__item">
      <div class="sidebar-details__item-icon">
        <i class="fal fa-info-circle" />
      </div>

      <div class="sidebar-details__item-content">
        <div class="sidebar-details__item-title" data-testid="payment-error-message">
          {{ paymentErrorMessage }}
        </div>

        <div class="sidebar-details__product-description">
          <span class="sidebar-details__subtitle-light">
            {{ this.$t('sparkpay.sales.detailed-sales-sidebar.transaction-status') }}
          </span>
        </div>
      </div>
    </div>

    <div v-if="content.dateCreated" data-testid="date-created" class="sidebar-details__item">
      <div class="sidebar-details__item-icon">
        <i class="fal fa-calendar-alt" />
      </div>

      <div class="sidebar-details__item-content">
        <div class="sidebar-details__item-title">
          <span data-testid="sale-day">
            {{ formatDay(sale.created_at) }}
          </span>

          <span class="sidebar-details__date-hour" data-testid="sale-hour">
            {{ formatHour(sale.created_at) }}
          </span>
        </div>

        <div class="sidebar-details__product-description">
          <span class="sidebar-details__subtitle-light">
            {{ saleStatusText }}
          </span>
        </div>
      </div>
    </div>

    <div
      v-if="content.refundContent && refundRequestDate"
      data-testid="refund-request-date"
      class="sidebar-details__item"
    >
      <div class="sidebar-details__item-icon">
        <i class="fal fa-calendar-alt" />
      </div>

      <div class="sidebar-details__item-content">
        <div class="sidebar-details__item-title">
          <span data-testid="sale-day">
            {{ formatDay(refundRequestDate) }}
          </span>

          <span class="sidebar-details__date-hour" data-testid="sale-hour">
            {{ formatHour(refundRequestDate) }}
          </span>
        </div>

        <div class="sidebar-details__product-description">
          <span class="sidebar-details__subtitle-light">
            {{ $t('sparkpay.sales.detailed-sales-sidebar.refund-analyzing-date') }}
          </span>
        </div>
      </div>
    </div>

    <div v-if="hasRefundedStatus" data-testid="refunded-date" class="sidebar-details__item">
      <div class="sidebar-details__item-icon">
        <i class="fal fa-calendar-alt" />
      </div>

      <div class="sidebar-details__item-content">
        <div class="sidebar-details__item-title">
          <span data-testid="sale-day">
            {{ formatDay(sale.refunded_at) }}
          </span>

          <span class="sidebar-details__date-hour" data-testid="sale-hour">
            {{ formatHour(sale.refunded_at) }}
          </span>
        </div>

        <div class="sidebar-details__product-description">
          <span class="sidebar-details__subtitle-light">
            {{ $t('sparkpay.sales.detailed-sales-sidebar.refund-done-date') }}
          </span>
        </div>
      </div>
    </div>

    <div v-if="content.baseValue" data-testid="base-value" class="sidebar-details__item">
      <div class="sidebar-details__item-icon">
        <i class="fal fa-usd-circle" />
      </div>

      <div class="sidebar-details__item-content">
        <div class="sidebar-details__item-title sidebar-details__item-title--info">
          {{ sale.base_price }}
        </div>

        <div class="sidebar-details__product-description">
          <span class="sidebar-details__subtitle-light">
            {{ this.$t('sparkpay.sales.detailed-sales-sidebar.base-price') }}
          </span>
        </div>
      </div>
    </div>

    <div v-if="content.totalFees" data-testid="total-fees" class="sidebar-details__item">
      <div class="sidebar-details__item-icon">
        <i class="fal fa-coins" />
      </div>

      <div class="sidebar-details__item-content">
        <div class="sidebar-details__item-title sidebar-details__item-title--danger">- {{ sale.total_fees }}</div>

        <div class="sidebar-details__product-description">
          <span class="sidebar-details__subtitle-light">
            {{ this.$t('sparkpay.sales.detailed-sales-sidebar.herospark-fees') }}
          </span>
        </div>
      </div>
    </div>

    <div v-if="content.couponCode" class="sidebar-details__item">
      <div class="sidebar-details__item-icon">
        <i class="fal fa-ticket-alt" />
      </div>

      <div class="sidebar-details__item-content">
        <div class="sidebar-details__item-title sidebar-details__item-title--danger">
          {{ content.couponCode }}
        </div>

        <div class="sidebar-details__product-description">
          <span class="sidebar-details__subtitle-light">
            Cupom de desconto utilizado
          </span>
        </div>
      </div>
    </div>

    <ProducerView
      v-if="viewOfProducer(sale)"
      :sale="sale"
      :hasNetValue="content.netValue"
      :is-mongo-data="!isStrategySales"
    />
    <AffiliateView v-if="viewOfAffiliate(sale)" :sale="sale" :hasNetValue="content.netValue" />
    <CoproducerView v-if="viewOfCoprodutor(sale)" :sale="sale" :hasNetValue="content.netValue" />

    <div class="sidebar-details__item">
      <div class="sidebar-details__item-icon">
        <i class="fal fa-wallet" />
      </div>

      <div class="sidebar-details__item-content">
        <div class="sidebar-details__item-title">
          {{ translatedPaymentMethod }}
        </div>

        <div class="sidebar-details__product-description">
          <span class="sidebar-details__subtitle-light">
            {{ this.$t('sparkpay.sales.detailed-sales-sidebar.payment-method-text') }}
          </span>
        </div>
      </div>
    </div>

    <div v-if="hasOrderBumpSales" data-testid="order-bump" class="sidebar-details__item">
      <div class="sidebar-details__item-icon">
        <i class="fal fa-shopping-cart" />
      </div>

      <div class="sidebar-details__item-content">
        <div class="sidebar-details__item-title">
          {{ $t('sparkpay.sales.detailed-sales-sidebar.order-bump') }}
        </div>

        <div class="sidebar-details__product-description">
          <span class="sidebar-details__subtitle-light">
            {{ $tc('sparkpay.sales.detailed-sales-sidebar.order-bump-count', content.orderBumpItems.length) }}
            <hs-icon
              icon="eye"
              :id="`order-bump-items-${sale.id}`"
              tabindex="0"
              class="sidebar-details__order-bump-icon"
            />
          </span>

          <b-popover
            custom-class="sidebar-details-popover"
            placement="bottomleft"
            triggers="focus"
            :target="`order-bump-items-${sale.id}`"
          >
            <div
              v-for="orderBumpItem in orderBumpList"
              :key="orderBumpItem.id"
              class="sidebar-details__order-bump-item"
            >
              <img
                v-if="orderBumpItem.coverUrl"
                :src="orderBumpItem.coverUrl"
                height="24"
                width="24"
                :alt="$t('sparkmembers.orderbump.related-item.img-alt')"
                class="sidebar-details__order-bump-item-cover-image"
              />

              <div class="sidebar-details__order-bump-item-title">
                {{ orderBumpItem.name }}
              </div>
            </div>
          </b-popover>
        </div>
      </div>
    </div>

    <div v-if="content.hasInstallmentsText" data-testid="installments-text" class="sidebar-details__item">
      <div class="sidebar-details__item-icon">
        <i class="fal fa-divide" />
      </div>

      <div class="sidebar-details__item-content">
        <div class="sidebar-details__item-title">
          {{ sale.installments_text }}
        </div>

        <div class="sidebar-details__product-description">
          <span class="sidebar-details__subtitle-light">
            {{ totalValueText }}
          </span>
        </div>
      </div>
    </div>

    <div v-else-if="content.bankSlipExpiration" data-testid="bank-slip-expiration" class="sidebar-details__item">
      <div class="sidebar-details__item-icon">
        <i class="fal fa-file-invoice-dollar" />
      </div>

      <div class="sidebar-details__item-content">
        <div class="sidebar-details__item-title" data-testid="bank-slip-expiration-date">
          {{ formatDay(sale.bank_slip_expiration_date) }}
        </div>

        <div class="sidebar-details__product-description">
          <span class="sidebar-details__subtitle-light">
            {{ this.$t('sparkpay.sales.detailed-sales-sidebar.due-date') }}
          </span>
        </div>
      </div>
    </div>

    <div v-if="content.refundContent" data-testid="refund-value" class="sidebar-details__item">
      <div class="sidebar-details__item-icon">
        <i class="fal fa-wallet" />
      </div>

      <div class="sidebar-details__item-content">
        <div class="sidebar-details__item-title" :class="refundValueClass">
          {{ sale.refund_price }}
        </div>

        <div class="sidebar-details__product-description">
          <span class="sidebar-details__subtitle-light">
            {{ refundValueText }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { PaymentStatusList, PaymentMethodsList } from '@/types/payments';
import AffiliateView from './AffiliateView.vue';
import CoproducerView from './CoproducerView.vue';
import ProducerView from './ProducerView.vue';
import PaymentSummaryMixin from '@/sparkpay/mixins/PaymentSummaryMixin.js';

export default {
  name: 'SidebarDetails',
  components: { AffiliateView, CoproducerView, ProducerView },
  mixins: [PaymentSummaryMixin],
  props: {
    sale: {
      type: Object,
      required: true,
    },
  },
  computed: {
    paymentMethod() {
      return this.sale.payment_method[0];
    },
    isPaymentCanceled() {
      return this.sale.status === PaymentStatusList.CANCELED;
    },
    paymentErrorMessage() {
      return this.sale.error_message || this.$t('sparkpay.sales.detailed-sales-sidebar.refused-by-bank');
    },
    translatedPaymentMethod() {
      return this.$t(`sparkpay.sales.detailed-sales-sidebar.payment-method.${this.paymentMethod}`);
    },
    isStrategySales() {
      return Boolean(this.sale?.is_strategy_sales);
    },
    isCreditCard() {
      return this.paymentMethod === PaymentMethodsList.CREDIT_CARD;
    },
    isMulticard() {
      return this.paymentMethod === PaymentMethodsList.MULTICARD;
    },
    isBankSlip() {
      return this.paymentMethod === PaymentMethodsList.BANK_SLIP_TRANSLATED;
    },
    hasRefundedStatus() {
      return this.sale.status === PaymentStatusList.REFUNDED;
    },
    isRefund() {
      return [PaymentStatusList.REFUND_ANALYZING, PaymentStatusList.REFUNDED, PaymentStatusList.CHARGEBACK].includes(
        this.sale.status
      );
    },
    saleStatusText() {
      return this.isPaymentCanceled
        ? this.$t('sparkpay.sales.detailed-sales-sidebar.transaction-date')
        : this.$t('sparkpay.sales.detailed-sales-sidebar.sale-date');
    },
    totalValueText() {
      return this.$t('sparkpay.sales.detailed-sales-sidebar.total-value-installments', { totalValue: this.sale.total });
    },
    refundValueText() {
      return this.sale.status === PaymentStatusList.REFUND_ANALYZING
        ? this.$t('sparkpay.sales.detailed-sales-sidebar.refund-contested-value')
        : this.$t('sparkpay.sales.detailed-sales-sidebar.refund-refunded-value');
    },
    refundValueClass() {
      return {
        'sidebar-details__refund-analysis-value': this.sale.status === PaymentStatusList.REFUND_ANALYZING,
        'sidebar-details__refund-done-value': this.sale.status === PaymentStatusList.REFUNDED,
      };
    },
    refundRequestDate() {
      const { status, refund_request_at, updated_at } = this.sale;

      if (status === PaymentStatusList.CHARGEBACK) return updated_at;

      return refund_request_at;
    },
    content() {
      const displayInstallments = this.isCreditCard || this.isMulticard || this.isStrategySales;

      return {
        baseValue: !this.isRefund,
        dateCreated: this.sale.status !== PaymentStatusList.REFUNDED,
        canceledStatus: this.isPaymentCanceled,
        totalFees: !this.isPaymentCanceled && this.sale.total_fees && !this.isRefund,
        netValue: Boolean(!this.isPaymentCanceled && this.sale.total_receive && !this.isRefund),
        hasInstallmentsText: displayInstallments && !this.isPaymentCanceled && !this.isRefund,
        bankSlipExpiration: this.isBankSlip && this.sale.bank_slip_expiration_date && !this.isRefund,
        refundContent: this.isRefund,
        affiliate_fees_cents: this.sale.affiliate_fees_cents,
        coproduction_fees_cents: this.sale.coproduction_fees_cents,
        coproductions_fees_cents: this.sale.coproductions_fees_cents,
        hasOrderBump: this.sale.order_bump_used,
        orderBumpItems: this.sale.order_bump_items,
        couponCode: this.sale.coupon_code,
      };
    },
    orderBumpList() {
      if (this.sale.is_strategy_sales) {
        return this.sale.order_bump_items?.map(item => ({
          id: item.item_id,
          coverUrl: item.product.cover_url,
          name: item.product.name,
        }));
      }
      return this.content.orderBumpItems?.map(item => ({
        id: item.id,
        coverUrl: item.cover_url,
        name: item.name,
      }));
    },
    hasOrderBumpSales() {
      return this.content.hasOrderBump && this.content.orderBumpItems.length;
    },
  },
  methods: {
    formatDay(date) {
      return dayjs(date).format('DD/MM/YYYY');
    },
    formatHour(date) {
      return dayjs(date).format('[às] HH:mm');
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-details {
  display: flex;
  flex-direction: column;
}

.sidebar-details__title {
  color: #6f6f6f;
  font-weight: 600;
  text-transform: uppercase;
}

.sidebar-details__item {
  display: grid;
  grid-template-columns: auto 1fr;
  margin-top: 16px;
}

.sidebar-details__order-bump-icon {
  cursor: pointer;
}

.sidebar-details__item-icon {
  width: 32px;
}

.sidebar-details__item-content {
  display: flex;
  flex-direction: column;
}

.sidebar-details__product-title {
  color: $grey;
  font-weight: 600;
}

.sidebar-details__product-description {
  font-size: 0.75rem;
  margin-top: 4px;
}

.sidebar-details__subtitle-light {
  color: #6f6f6f;
}

.sidebar-details__description-value {
  color: $grey;
}

.sidebar-details__item-title {
  font-size: 0.875rem;
  font-weight: 600;
  color: $grey;
}

.sidebar-details__order-bump-item {
  width: 100%;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    padding-bottom: 16px;
    border-bottom: 1px solid #bababa;
    margin-bottom: 16px;
  }
}

.sidebar-details__order-bump-item-cover-image {
  min-width: 24px;
  height: 24px;
  border-radius: 4px;
  object-fit: cover;
}

.sidebar-details__order-bump-item-title {
  margin-left: 12px;
  font-size: 0.875rem;
  color: $grey;
}

.sidebar-details__item-title--info {
  color: #418cfc;
}

.sidebar-details__item-title--danger {
  color: #f94343;
}

.sidebar-details__item-title--success {
  color: #539623;
}

.sidebar-details__date-hour {
  font-size: 0.75rem;
  color: #6f6f6f;
}

.sidebar-details__refund-analysis-value {
  color: #7427f1;
}

.sidebar-details__refund-done-value {
  color: #539623;
}
</style>
