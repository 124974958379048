<template>
  <div class="sidebar-details">
    <ValueDescription
      v-if="hasNetValue"
      testid="net-value"
      icon="coins"
      :receiving="true"
      :value="valueText(true, coproductionValue(sale))"
      :description="$t('sparkpay.sales.detailed-sales-sidebar.net-value')"
    />
  </div>
</template>

<script>
import PaymentSummaryMixin from '@/sparkpay/mixins/PaymentSummaryMixin.js';
import ValueDescription from './ValueDescription.vue';

export default {
  name: 'CoproducerView',
  components: { ValueDescription },
  mixins: [PaymentSummaryMixin],
  props: {
    sale: {
      type: Object,
      required: true,
    },
    hasNetValue: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-details {
  display: flex;
  flex-direction: column;
}
</style>
