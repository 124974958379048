<template>
  <div class="sidebar-details">
    <ValueDescription
      v-if="comissionSaleValue"
      testid="coproduction-commission"
      icon="coins"
      :value="valueText(false, comissionSaleValue)"
      :description="comissionDescriptionText"
    />

    <ValueDescription
      v-if="hasNetValue"
      testid="net-value"
      icon="coins"
      :receiving="true"
      :value="valueText(true, producerSaleValue)"
      :description="$t('sparkpay.sales.detailed-sales-sidebar.net-value')"
    />
  </div>
</template>

<script>
import PaymentSummaryMixin from '@/sparkpay/mixins/PaymentSummaryMixin.js';
import ValueDescription from './ValueDescription.vue';

export default {
  name: 'ProducerView',
  components: { ValueDescription },
  mixins: [PaymentSummaryMixin],
  props: {
    sale: {
      type: Object,
      required: true,
    },
    hasNetValue: {
      type: Boolean,
      required: true,
    },
    isMongoData: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    getValue(mongoFunction, normalFunction) {
      return this.isMongoData ? mongoFunction(this.sale) : normalFunction(this.sale);
    },
  },
  computed: {
    comissionDescriptionText() {
      return "Total de comissões nesta venda. <a href='/sparkpay/affiliates_coproducer_report'>Clique para ver os detalhes das comissões entre afiliados e coprodutores.</a>";
    },
    producerSaleValue() {
      return this.getValue(this.producerValueMongoCents, this.producerValueCents);
    },
    comissionSaleValue() {
      return this.getValue(this.comissionValueMongoCents, this.comissionValueCents);
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-details {
  display: flex;
  flex-direction: column;
}
</style>
