<template>
  <div :data-testid="testid" class="value-description__item">
    <div class="value-description__item-icon">
      <i :class="`fal fa-${icon}`" />
    </div>

    <div class="value-description__item-content">
      <div :class="`value-description__item-title value-description__item-title--${color}`">
        {{ value }}
      </div>

      <div class="value-description__product-description">
        <span v-html="description" class="value-description__subtitle-light" />
      </div>
    </div>
  </div>
</template>

<script>
const SUCCESS = 'success';
const DANGER = 'danger';

export default {
  name: 'ValueDescription',
  props: {
    testid: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      required: true,
    },
    receiving: {
      type: Boolean,
      default: () => false,
    },
    value: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
  computed: {
    color() {
      return this.receiving ? SUCCESS : DANGER;
    },
  },
};
</script>

<style lang="scss">
.value-description__item {
  display: grid;
  grid-template-columns: auto 1fr;
  margin-top: 16px;
}

.value-description__item-icon {
  width: 32px;
}

.value-description__item-content {
  display: flex;
  flex-direction: column;
}

.value-description__product-title {
  color: $grey;
  font-weight: 600;
}

.value-description__product-description {
  font-size: 0.75rem;
  margin-top: 4px;
}

.value-description__subtitle-light {
  color: #6f6f6f;

  a {
    color: $purple-dark;
  }
}

.value-description__description-value {
  color: $grey;
}

.value-description__item-title {
  font-size: 0.875rem;
  font-weight: 600;
  color: $grey;
}

.value-description__item-title--info {
  color: #418cfc;
}

.value-description__item-title--danger {
  color: #f94343;
}

.value-description__item-title--success {
  color: #539623;
}
</style>
